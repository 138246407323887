import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MarketingAndCookies({
  children
}) {
  return <LegalDoc title="Marketing & Cookies" updated="9 Dec 2019">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Cookies are pieces of code that allow for personalisation of your website experience by saving information such as your unique identifier and other preferences. We use cookies for a number of reasons, like helping us determine the popularity of certain content, improving our services and your experience, and to better understand your online activity. The cookies we use fall into one or more of the following categories. `}</p>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Analytical/Performance Cookies`}</strong>{`: These allow us to recognise and count the number of visitors and to see how visitors move around the website when they are using it. This helps us improve the way the website works, for example, by ensuring that users are finding what they are looking for easily.`}</li>
      <li parentName="ol"><strong parentName="li">{`Functionality cookies`}</strong>{`: These are used to recognise you when you return to the website. This enables us to personalise our content for you, and remember your preferences (for example, your choice of language or region).`}</li>
      <li parentName="ol"><strong parentName="li">{`Targeting cookies`}</strong>{`: These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the marketing messages we send to you more relevant to your interests. We may also share this information with third parties who provide a service to us for this purpose.`}</li>
      <li parentName="ol"><strong parentName="li">{`Third-party cookies`}</strong>{`: Advertisers and other third parties may use their own cookies tags when you click on an advertisement or link to our website. These advertisers and other third parties are responsible for setting out their own cookie and privacy policies.`}</li>
    </ol>
    <h2>{`Opting out`}</h2>
    <h3>{`Cookies`}</h3>
    <p>{`If you do not wish to receive cookies, most browsers allow you to change your cookie settings. These settings will typically be found in the “options” or “preferences” menu of your browser. Please note that if you choose to disallow cookies you may not be able to use the full functionality of our website. `}</p>
    <h3>{`Emails`}</h3>
    <p>{`We may send you emails from time to time with relevant information about Fidel and our services. For some messages, we may use personal information we collect about you to help us determine the most relevant information to share with you. If you do not want to receive such messages, you can change your marketing preferences by clicking the unsubscribe link at the bottom of our emails. If you have specific questions or concerns, contact us at `}<a parentName="p" {...{
        "href": "mailto:privacy@fidelapi.com"
      }}>{`privacy@fidelapi.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      